/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    h2: "h2",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "We trigger an event when we make a change to a resource or complete a process, for example, when we change the status of a processing account."), "\n", React.createElement(_components.p, null, "When you subscribe to an event, each time we trigger it we send you a notification to inform you about the change. We send the notification by a webhook request to an endpoint that you specify when you subscribe to the event. For more information about how to subscribe to events, go to ", React.createElement(_components.a, {
    href: "/guides/integrate/events-subscriptions/create-events-subscription"
  }, "Create an events subscription"), "."), "\n", React.createElement(Admonition, {
    type: "default"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " If we don't receive a 200 response to the webhook request, we retry the request up to five times. If the request is still unsuccessful after five retry attempts, we contact the support email address that you specified when you subscribed to the event.")), "\n", React.createElement(_components.h2, null, "CloudEvents attributes"), "\n", React.createElement(_components.p, null, "We use the ", React.createElement(_components.a, {
    href: "https://cloudevents.io/"
  }, "CloudEvents standard"), " to format our event notifications. Each notification has the following attributes:"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Attribute"), React.createElement(_components.th, {
    align: "left"
  }, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "specversion")), React.createElement(_components.td, {
    align: "left"
  }, "Version of the CloudEvents specification that the event notification follows.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "type")), React.createElement(_components.td, {
    align: "left"
  }, "Type of event that occurred.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "version")), React.createElement(_components.td, {
    align: "left"
  }, "Version of the event type in our specification.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "source")), React.createElement(_components.td, {
    align: "left"
  }, "Indicates the origin of the event.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "id")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier of the event.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "time")), React.createElement(_components.td, {
    align: "left"
  }, "Time stamp of the event.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "datacontenttype")), React.createElement(_components.td, {
    align: "left"
  }, "Indicates the format of the data object.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "data")), React.createElement(_components.td, {
    align: "left"
  }, "Information about the change that triggered the event.")))), "\n", React.createElement(_components.h3, null, "Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"specversion\": \"1.0\",\n  \"type\": \"processingAccount.status.changed\",\n  \"version\": \"1.0\"\n  \"source\": \"payroc\",\n  \"id\": \"123e4567-e89b-12d3-a456-426614174000\",\n  \"time\": \"2024-05-12T12:34:56.789Z\",\n  \"datacontenttype\": \"application/json\",\n  \"data\": {\n    \"processingAccountId\": \"12345678\",\n    \"status\": \"entered\"\n  }\n}\n")), "\n", React.createElement(_components.h2, null, "Events"), "\n", React.createElement(_components.p, null, "You can subscribe to the following events with our API:"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Type"), React.createElement(_components.th, {
    align: "left"
  }, "Change"), React.createElement(_components.th, {
    align: "left"
  }, "Resource"), React.createElement(_components.th, {
    align: "left"
  }, "Version"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "/knowledge/events/processingaccount-status-changed"
  }, "processingAccount.status.changed")), React.createElement(_components.td, {
    align: "left"
  }, "We have changed the status of a processing account."), React.createElement(_components.td, {
    align: "left"
  }, "Boarding"), React.createElement(_components.td, {
    align: "left"
  }, "1.0")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "/knowledge/events/processingaccount-funding-status-changed"
  }, "processingAccount.funding.status.changed")), React.createElement(_components.td, {
    align: "left"
  }, "We have changed the funding status of a processing account."), React.createElement(_components.td, {
    align: "left"
  }, "Boarding"), React.createElement(_components.td, {
    align: "left"
  }, "1.0")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
